import React, { useState, useEffect } from 'react';

interface CountdownProps {
  targetDate: Date | number; // Date object or timestamp in milliseconds
  format?: 'html' | 'text'
}

type TimeLeft = {
  hours: number;
  minutes: number;
  seconds: number;
}

export const Countdown: React.FC<CountdownProps> = ({ targetDate, format = 'html' }) => {
  const target = typeof targetDate === 'number' ? targetDate : targetDate.getTime();

  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = target - now;
      let timeLeft = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
  
      if (difference > 0) {
        timeLeft = {
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [target]);

  if (format == 'text') {
    return [
      String(timeLeft.hours).padStart(2, '0'),
      String(timeLeft.minutes).padStart(2, '0'),
      String(timeLeft.seconds).padStart(2, '0')
    ].join(':');
  }

  return (
    <>
      {timeLeft.hours > 0 && (
        <span>{String(timeLeft.hours)} hours and </span>
      )}
      <span>{String(timeLeft.minutes)} minutes</span>
      {/* <span>{String(timeLeft.seconds).padStart(2, '0')} seconds</span> */}
    </>
  );
};
