'use client'

import { useEffect, useState } from "react"
import { search, searchResults } from "@/lib/search/server-actions.server"
import { SearchResult } from "./SearchResult.client"
import { useQueryStates } from "nuqs"
import { searchParamsSearch } from "@/lib/searchParams"
import { SearchHelp } from "./SearchHelp.client"
import { NoResults } from "./NoResults"
import { useSearch } from "@/contexts/SearchProvider"

export const SUResults = ({resultType = 'SearchResult', query = undefined, filters = undefined}: {
  query?: string,
  filters?: string[]
  resultType?: 'SearchResult'
}) =>  {
  const [searchResults, setSearchResults] = useState<searchResults>()
  const [isWorking, setIsWorking] = useState(false)
  const [searchParams, setSearchParams] = useQueryStates(searchParamsSearch)
  const { current } = useSearch()

  const searchQuery = query ?? searchParams.searchQuery
  const searchFiltersJson = JSON.stringify(filters ?? searchParams.searchFiltersProducts ?? undefined)
  
  useEffect(() => {
    if (!searchQuery) {
      return
    }

    // Initiate the search (server action).
    search(searchQuery, {products: JSON.parse(searchFiltersJson ?? 'null')}).then((results) => {
      // Set the search results.
      setSearchResults(results)
      setIsWorking(false)
    })

    setIsWorking(true)
    // Prevent further searches to protect the API from search stampedes.
  }, [searchQuery, searchFiltersJson])

  if (isWorking) {
    return <SearchHelp isWorking={isWorking} hasResults={false} />
  }

  if (searchResults?.data.result.hits == undefined) {
    return <SearchHelp isWorking={isWorking} hasResults={false} />
  }

  if (searchResults.data.result.hits.length == 0) {
    return <>
      <NoResults />
      <SearchHelp isWorking={false} hasResults={false} />
    </>
  }

  return <>
    {searchResults?.data.result.hits.length > 0 && (
      <div className="flex flex-col gap-4">
        {searchResults?.data.result.hits.map(hit =>  <SearchResult key={hit._id} {...hit} /> )}
      </div>
    )}
    <SearchHelp isWorking={false} hasResults={true} />
  </>
}