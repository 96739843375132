import { Button } from "@/components/base/Button"
import { ack } from "@/redux/features/consent"
import { useAppDispatch } from "@/redux/hooks"
import HugSvg from '@/assets/hug.svg';
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";


export const AiGuidelinesAgreement = () => {
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLButtonElement>(null)

  function agree() {
    dispatch(ack('aiGuidelines'))
  }

  useEffect(() => {
    ref.current?.focus()
  }, [])

  return <div className="flex items-center h-full">
  <div className="p-4 h-fit w-fit mx-auto text-navy-600 text-center pb-24">
  <HugSvg className="w-48 mx-auto mt-12" />
    <h2 className="text-2xl font-medium my-4">Welcome to Acquia Copilot</h2>
    <div className="max-w-sm p-4">
      <p>By using Acquia Copilot, you are agreeing to our <a className="text-blue-600 underline" target="_blank" href="https://www.acquia.com/legal/ai-user-guidelines">AI user guidelines</a> and understand that Copilot’s responses are for informational purposes only.</p>
      <Button ref={ref} onClick={() => agree()} className="flex items-center gap-2 mx-auto"><SparklesIcon className="w-4" role="none" /> Agree</Button>
    </div>
  </div>
</div>
  
}