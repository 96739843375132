import { ForwardRefExoticComponent, SVGProps, RefAttributes, ReactNode } from "react";
import { DrawInterface } from "./SidebarDraw.client";
import { SidebarSearch } from "../search/SidebarSearch.client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { DrawOptions } from "@/contexts/SidebarDrawProvider";


export class SearchDraw implements DrawInterface {
  public readonly type: DrawOptions = 'search';
  public readonly Icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref"> & { title?: string; titleId?: string; } & RefAttributes<SVGSVGElement>> = MagnifyingGlassIcon;
  public readonly title: string = 'Search';
  public readonly footer: ReactNode = null;
  public get children():ReactNode {
    return <SidebarSearch />
  }
}