'use client'

import Loading from '@/assets/loading.svg'
import { SupportPrompt } from "./SupportPrompt.client"
import { useSearch } from "@/contexts/SearchProvider"

export const SearchHelp = ({hasResults = false, isWorking}: {
  hasResults: boolean
  isWorking: boolean
}) => {

  const { current } = useSearch()

  if (isWorking) {
    return <div className="p-2 flex flex-col gap-y-2 place-items-center">
      <div className='animate-pulse py-10'>
        <Loading />
      </div>
    </div>
  }
  
 return <SupportPrompt  query={current?.query} />
}