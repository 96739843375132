import { useChat } from "@/contexts/ChatProvider"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { DrupalTaxonomyTermProduct } from "@/types"
import { Fragment } from "react"

export const Suggestions = () => {
  const { append, createMessage } = useChat()
  const { resource } = useContentFromRoute()

  let relevantProducts:DrupalTaxonomyTermProduct[] = []
  relevantProducts.push(...(resource?.field_products ?? (resource?.field_product ? [resource.field_product] : [])))
  // Make unique.
  relevantProducts = relevantProducts.reduce((r, c) => {
    if (!r.map(p => p.id).includes(c.id)) {
      r.push(c)
    }
    return r
  }, [] as DrupalTaxonomyTermProduct[])

  const common_questions = relevantProducts?.filter(product => product.field_common_search_questions?.length);
  
  return <div className="px-8 py-2">
    {common_questions?.map(product => <Fragment key={product.id}>
      <ul className="flex flex-col space-y-3">
      {product.field_common_search_questions?.map((question, i) => 
        <li key={i} className=""><button className="text-left block w-full px-4 py-2 bg-teal-300 hover:bg-teal-400 rounded-lg" onClick={(e) => append(createMessage(question))}>{question}</button></li>
      )}
      </ul>
    </Fragment>)}
    {(common_questions === undefined || common_questions.length < 1) && (<>
      <ul className="flex flex-col space-y-3">
        <li className="w-full"><button className="text-left block w-full px-4 py-2 bg-teal-300 hover:bg-teal-400 rounded-lg" onClick={(e) => append(createMessage("How do I upload an SSL certificate?"))}>How do I upload an SSL certificate?</button></li>
        <li className="w-full"><button className="text-left block w-full px-4 py-2 bg-teal-300 hover:bg-teal-400 rounded-lg" onClick={(e) => append(createMessage("How do I get PHP error logs?"))}>How do I get PHP error logs?</button></li>
        <li className="w-full"><button className="text-left block w-full px-4 py-2 bg-teal-300 hover:bg-teal-400 rounded-lg" onClick={(e) => append(createMessage("Can I run a task on a schedule?"))}>Can I run a task on a schedule?</button></li>
        <li className="w-full"><button className="text-left block w-full px-4 py-2 bg-teal-300 hover:bg-teal-400 rounded-lg" onClick={(e) => append(createMessage("What security features are available?"))}>What security features are available?</button></li>
      </ul>
    </>)}
  </div>
}
