import { ProductLogo } from "@/components/assets/ProductLogo"
import { InfoBubble } from "@/components/widget/InfoBubble"
import { useProducts } from "@/contexts/ProductsProvider"
import { useSearch } from "@/contexts/SearchProvider"
import { DrupalTaxonomyTermProduct } from "@/types"
import { Menu } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { PlusCircleIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { Fragment } from "react"

export const ProductFilters = ({className = ''}: {
  className?: string
}) => {
  const { products } = useProducts()
  const { search, current, clear } = useSearch()

  const activeFilters = products.filter(p => current?.filters?.products?.includes(p.name))

  function getTopLevelProducts() {
    return products
    .filter(p => p.field_search_filter)
    .filter(p => p.parent[0].id == 'virtual').sort((a, b) => {
      if (a.weight == b.weight) {
        return a.name > b.name ? 1 : -1;
      }
      return a.weight > b.weight ? 1 : -1
    })
  }

  function getChildren(parent: DrupalTaxonomyTermProduct) {
    return products
      .filter(p => p.field_search_filter)  
      .filter(t => t.parent.map(p => p.id).includes(parent.id));
  }

  function addProductFilter(product: DrupalTaxonomyTermProduct) {
    if (current) {
      search(current.addProductFilter(product.name))
    }
    else {
      search('', {
        products: [product.name]
      })
    }
  }

  function removeProductFilter(product: DrupalTaxonomyTermProduct) {
    const products = current?.filters?.products?.filter(name => name != product.name) ?? undefined
    if (current) {
      search(current.augment(current.query, { products }, current.referer))
    }
  }

  function clearFilters() {
    if (current) {
      search(current.augment(current.query, { products: [] }, current.referer))
    }
  }

  function filterIsActive(product: DrupalTaxonomyTermProduct) {
    return activeFilters.filter(f => f.id == product.id).length > 0
  }

  function filterIsAvailable(product: DrupalTaxonomyTermProduct) {
    if (activeFilters.length == 0) {
      return true
    }

    if (filterIsActive(product)) {
      return false
    }

    const hasActiveTopLevelProduct = activeFilters.filter(f => f.parent[0].id == "virtual").length > 0
    const hasActiveAddon = activeFilters.filter(f => f.parent[0].id != "virtual").length > 0

    if (hasActiveAddon) {
      if (hasActiveTopLevelProduct) {
        return false
      }
      return activeFilters.filter(f => f.parent.map(p => p.id).includes(product.id)).length > 0
    }
  
    // If has active top-level product, then must be addon of.
    if (hasActiveTopLevelProduct) {
      const topLevelProduct = activeFilters.filter(f => f.parent[0].id == "virtual")[0]
      return product.parent.filter(p => p.id == topLevelProduct.id).length > 0
    }

    // If there is a top-level product in the filters already,and this is a top-level product, then
    // it should be disabled.
    if (product.parent[0].id == "virtual") {
      if (hasActiveTopLevelProduct) {
        return false
      }
      return activeFilters.filter(f => f.parent.map(p => p.id).includes(product.id)).length > 0
    }

    // Is child add-on.
    return product.parent.map(p => activeFilters.filter(f => f.id == p.id).length > 0).length > 0
  }

  return <div className={classNames(className, 'flex gap-2 items-center')}>
    <h3 className="font-medium">Filter by product:</h3>
    <Menu as='div' className="relative">
      <Menu.Button className="group relative flex items-center">
        <PlusCircleIcon className="w-5 text-blue-600" />
        <div className="absolute pl-7">
          <InfoBubble className="hidden group-hover:block group-focus:block whitespace-nowrap" pointer="left">Add filter</InfoBubble>
        </div>
      </Menu.Button>
      <Menu.Items as="ul" className="absolute flex flex-col gap-0.5 bg-white w-[344px] rounded p-2 shadow-lg max-h-[80dvh] overflow-y-auto">
        {getTopLevelProducts().filter(p => filterIsActive(p) || filterIsAvailable(p) || activeFilters.length == 0).map(product => (
          <Fragment key={product.id}>
          <Menu.Item 
            onClick={() => addProductFilter(product)}
            // Disable if is not an active filter addon filter is not of product.
            disabled={!filterIsAvailable(product)}
            as='li' 
            className={({active, disabled}) => classNames(active ? 'bg-gray-400 rounded' : '', "flex p-1 gap-2 items-center", disabled ? "text-gray-600" : "cursor-pointer")}>
              <ProductLogo name={product.field_product_logo} className="w-4" />
              {product.name}
          </Menu.Item>
          {getChildren(product).map(addon => (
            <Menu.Item 
              disabled={!filterIsAvailable(addon)}
              onClick={() => addProductFilter(addon)}
              key={addon.id} 
              as='li'
              className={({active, disabled}) => classNames(active ? 'bg-gray-400 rounded' : '', "flex p-1 gap-2 items-center pl-4", disabled ? "text-gray-600" : "cursor-pointer")}>
                <ProductLogo name={addon.field_product_logo} className="w-4" />
                {addon.name}
            </Menu.Item>
          ))}
          </Fragment>
        ))}
      </Menu.Items>
    </Menu>
    {activeFilters.map(product => (
      <span key={product.id} title="Remove filter" aria-label="Remove filter" className="border rounded-xl pl-2 px-1 flex gap-1 cursor-pointer" onClick={() => removeProductFilter(product)}>
        {product.name}
        <XMarkIcon className="w-4" />
      </span>
    ))}
    {activeFilters.length > 0 && (
      <div className="flex-grow text-right">
        <button className="text-blue-600" onClick={() => clearFilters()}>Clear filters</button>
      </div>
    )}
  </div>
}