'use client';

import { Body } from '@/components/wysiwyg/Body';
import classNames from 'classnames';
import { parse } from 'marked';
import './loading.css';
import HugSvg from '@/assets/hug.svg';
import Link from 'next/link';
import { Suggestions } from './Suggestions';
import { ReactNode, useId, useRef, useState } from 'react';
import { SupportHelp } from './SupportHelp.client';
import { Info } from '@/components/widget/info';
import { useProducts } from '@/contexts/ProductsProvider';
import { ProductLogo } from '@/components/assets/ProductLogo';
import { DrupalTaxonomyTermProduct, ProductLogoNames } from '@/types';
import { ArrowPathIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { sendGTMEvent } from '@next/third-parties/google';
import { useChat } from '@/contexts/ChatProvider';
import { AcquiaFeedback } from './AcquiaFeedback.client';
import { ChatMessage as ChatMessageType } from '@/lib/copilot/types';

export const Messages = () => {
  const id = useId();
  
  const { messages, isLoading, error } = useChat();

  if (isLoading) {
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  if (!messages.length) {
    return <GettingStarted />
  }

  const lastMessageType = messages[messages.length - 1].role

  if (error) {
    console.error(error.message)
  }

  const displayMessages = messages.filter(m => m.role != 'system') as ChatMessageType<'user' | 'assistant' | 'data' | 'status'>[];

  return <div className='flex flex-col gap-4 p-4' role='log' aria-live='polite' aria-relevant="additions" aria-label='Acquia Copilot'>
    {displayMessages.map((m, i) => <ChatMessage key={i} {...m} isLoading={isLoading} isLast={i == (messages.length - 1) } />)}
    {isLoading && 
      <MessageRow orientation='left'>
        <div className='max-w-lg mr-auto'>
          <div id={id} className="mr-auto loader w-6" />
          { lastMessageType == 'user' && (
            <>
              <span className='sr-only'>Please wait...</span>
              <p className='text-sm italic text-gray-600'>This may take a few moments to consult our knowledge bases and construct a response.</p>
            </>
          )}
        </div>
      </MessageRow>
    }
    {displayMessages.length >= 1 && !isLoading &&  (
      <MessageRow orientation='left'>
        <SupportHelp isChat={true} />
      </MessageRow>
    )}
    {error && <div className="bg-orange-300 border-orange-600 border-2 p-4 rounded-lg">An error occured. Please try again or contact support.</div>}
  </div>
}

function GettingStarted() {
  return <div className='text-center'>
    <HugSvg className="w-24 mx-auto mt-12" />
    <h3 className='font-medium text-xl mt-4'>How can I help today?</h3>
    <h4 className='font-medium mt-6'>Suggestions</h4>
    <Suggestions />
  </div>
}

export function MessageRow({ orientation, children }: {
  children: ReactNode
  orientation: 'left' | 'right'
}) {
  return <div className={classNames('flex', orientation == 'left' ? '' : 'flex-row-reverse')}>
    {children}
    <span className='w-8' />
  </div>
}

type Message<T extends string> = ChatMessageType<T> & {
  isLoading: boolean
  isLast: boolean
  role: T
}

function ChatMessage(message: Message<'assistant' | 'status' | 'data' | 'user'>) {
  switch (message.role) {
    case 'assistant':
        return <MessageRow orientation='left'>
            <AssistantChatMessage {...message as Message<'assistant'>} />
          </MessageRow>
    case 'status':
      return <MessageRow orientation='right'>
            <StatusChatMessage {...message as Message<'status'>} />
          </MessageRow>
    case 'data':
        return <MessageRow orientation='left'>
            <DataChatMessage {...message as Message<'data'>} />
          </MessageRow>
    case 'user':
        return <MessageRow orientation='right'>
            <UserChatMessage {...message as Message<'user'>} />
          </MessageRow>
  }
}

function UserChatMessage({content, isLoading, isLast}: Message<'user'>) {
  function UserMessageOptions({ children }: {
    children:ReactNode
  }) {
    const [retried, setRetry] = useState<boolean>()
    const { reload } = useChat();

    if (isLoading || !isLast ) {
      return <>{children}</>
    }

    function doRetry() {
      reload()
      setRetry(true)
    }
  
    const buttonClass = 'p-2 rounded-lg transition-all duration-300 hover:bg-gray-400'
  
    return <div className='flex flex-col gap-2 group relative'>
      {children}
      <div className='flex ml-auto group-hover:flex bg-white rounded-lg border border-gray-600 p-1 items-center gap-2 text-navy-600 shadow w-fit'>
        <button title="Retry" onClick={() => doRetry()} className={classNames(buttonClass, 'flex items-center gap-2')}>
          <><ArrowPathIcon className={classNames('w-5', retried ? 'animate-spin' : '')} title="Retry" aria-hidden="true" /><span className=''>Retry</span></>
        </button>
      </div>
    </div>
  }
  return <UserMessageOptions>
    <div 
      aria-label='User message'
      className={classNames(
        'ml-auto bg-blue-400 text-navy-600',
        "p-4 rounded-lg w-fit"
      )}
      >
    <p className='sr-only'>User: </p>
    {content}
  </div>
  </UserMessageOptions>
}


function AssistantChatMessage({content, isLoading, isLast}: Message<'assistant'>) {

  const ref = useRef<HTMLDivElement>(null);

  function extractCitations(content: string): string {
    return content.replaceAll(/【\d+:\d+†.*】/g, '');
  }

  function AssistantMessageOptions({ children }: {
    children:ReactNode
  }) {

    const [copied, setCopied] = useState(false)
    const [liked, setLiked] = useState<boolean>()
    const [retried, setRetry] = useState<boolean>()
    const { reload } = useChat();

    function doRetry() {
      reload()
      setRetry(true)
    }

    const voted = liked !== undefined;
    
    if (isLoading) {
      return <>{children}</>
    }

    function copyToClipboard() {
      navigator.clipboard.write([new ClipboardItem({
        ["text/html"]: new Blob([ref.current?.innerHTML ?? ''], { type: 'text/html'})
      })])
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 3000)
      })
      .catch(err => {
        setCopied(false)
        console.error(err);
      });
  }

  function like(vote?: boolean) {
    setLiked(vote)
    if (vote !== undefined) {
      sendGTMEvent({
        event: vote ? 'chatLike' : 'chatDislike',
        value: true
      })
    }
  }

  const buttonClass = 'p-2 rounded-lg transition-all duration-300 hover:bg-gray-400'

    return <div className='flex flex-col gap-2 group relative'>
      {children}
      <div className='flex group-hover:flex bg-white rounded-lg border border-gray-600 p-1 items-center gap-2 text-navy-600 shadow w-fit'>
        <button onClick={() => copyToClipboard()} className={classNames(buttonClass)}>
          {copied ? (
            <><ClipboardDocumentCheckIcon className='w-5' aria-hidden="true" /><span className='sr-only'>copied!</span></>
          ) : (
            <><ClipboardDocumentListIcon className='w-5' aria-hidden="true" /><span className='sr-only'>copy</span></>
          )}
        </button>
        <span className='border-s-2 border-gray-600'>&nbsp;</span>
        <button 
          className={classNames(buttonClass, voted && liked ? 'text-teal-600' : 'disabled:text-gray-800 disabled-hover:border-transparent')} 
          disabled={voted && !liked} 
          onClick={() => like(voted ? undefined : true)}
        >
          <HandThumbUpIcon className='w-5' aria-hidden="true" /><span className='sr-only'>Like</span>
        </button>
        <button 
          className={classNames(buttonClass, voted && !liked ? 'text-pink-600' : 'disabled:text-gray-800 disabled-hover:border-transparent')} 
          disabled={voted && liked} 
          onClick={() => like(voted ? undefined : false)}
        >
          <HandThumbDownIcon className='w-5' aria-hidden="true" /><span className='sr-only'>Dislike</span>
          { voted && !liked && <AcquiaFeedback /> }
        </button>
        {isLast && (
          <>
            <span className='border-s-2 border-gray-600'>&nbsp;</span>
            <button title="Retry" onClick={() => doRetry()} className={classNames(buttonClass, 'flex items-center gap-2')}>
              <>
                <ArrowPathIcon className={classNames('w-5', retried ? 'animate-spin' : '')} title="Retry" aria-hidden="true" />
                <span className='sr-only'>Retry</span>
              </>
            </button>
          </>
        )}
      </div>
    </div>
  }

  return <AssistantMessageOptions>
        <div 
          className={classNames(
            'bg-gray-400 text-black mr-auto',
            "p-4 rounded-lg w-fit"
          )}
        >
        <p className='sr-only'>Acquia Copilot: </p>
        <div ref={ref} className='max-w-lg'>
          <Body value={parse(extractCitations(content as string), {async: false}) as string} />
        </div>
      </div>
    </AssistantMessageOptions>
}

export type BedrockAgentCitation = {
  content: {
    type: 'TEXT',
    text: string
  },
  location: {
    s3Location: {
      uri: string
    },
    type: 'S3',
  },
  metadata: {
    bundle: string,
    changed: number,
    created: number,
    path: string,
    product: ProductLogoNames,
    title: string
  }
}

function DataChatMessage({data}: Message<'data'>) {

  if (!data) {
    return;
  }

  const citations: BedrockAgentCitation[] = data as unknown as BedrockAgentCitation[]

  if (!citations.length) {
    return <></>
  }

  return <div 
  className={classNames(
    'border border-gray-400 bg-gray-300 text-teal-800 mr-auto',
    "p-4 rounded-lg w-fit",
    "flex flex-col gap-2",
  )}>
    <div className='flex items-center gap-1'>
      <h2 className='font-medium '>See also</h2>
      <Info pointer='left' className='w-48 text-xs -top-11 left-5'>Data from these resources was used to construct the above reponse.</Info>
    </div>
    <ul className='pl-4 flex flex-col gap-1'>
    {citations.map(citation => <li key={citation.location.s3Location.uri}>
      <Link className='hover:text-blue-600 hover:underline flex items-center gap-2' href={citation.metadata.path}>
        <FromProductLogo productName={citation.metadata.product} />
        {citation.metadata.title}
      </Link>
    </li>)}
    </ul>
  </div>
}

function FromProductLogo({ productName }: {productName: string}) {
  const { products } = useProducts()
  const product = products.reduce<DrupalTaxonomyTermProduct|null>((r, c) => c.name == productName ? c : r, null)

  if (!product) {
    return <></>
  }

  return <span role="none" className="flex gap-1 items-end font-medium">
  <ProductLogo title={productName} name={product.field_product_logo} className="w-4 pb-1" />
</span>
}


function StatusChatMessage({content}: Message<'status'>) {
  return <MessageRow orientation="left">
          <div 
            aria-label='Status message'
            className={classNames(
              'border border-gray-400 bg-gray-300 text-orange-800 mr-auto',
              "p-4 rounded-lg w-fit",
              "flex flex-col gap-2",
            )}>
              <p className='sr-only'>Status: </p>
              {content}
          </div>
        </MessageRow>
}