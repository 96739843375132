'use client'
import { Button } from "@/components/base/Button";
import { useCopilotPreview } from "@/contexts/CopilotPreviewProvider";
import { useProducts } from "@/contexts/ProductsProvider";
import { useSearch } from "@/contexts/SearchProvider";
import { useSidebarDraw } from "@/contexts/SidebarDrawProvider";
import { MagnifyingGlassIcon, SparklesIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { signIn } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useState } from "react";
import HugSvg from '@/assets/hug.svg';
import { useAppDispatch } from "@/redux/hooks";
import { ack } from "@/redux/features/consent"

export function LoginButton({title}: { title: string}) {
  const [clicked, setClicked] = useState(false);
  const { openDraw } = useSearch();

  function click() {
    signIn('okta')
    setClicked(true)
  }

  return <div className="flex items-center h-full">
      <div className="p-4 h-fit w-fit mx-auto text-navy-600 text-center pb-24">
        <div className="flex gap-2 items-center mx-auto w-fit">
          <Button onClick={() => click()} className={classNames(clicked ? 'animate-pulse' : '', "flex items-center gap-2")}><UserCircleIcon className="w-8" role="none" /> {clicked ? 'Please wait' : 'Sign in'}</Button>
        </div>
        <h2 className="text-xl font-medium my-4">Sign in to use {title}</h2>
        <div className="max-w-sm p-4 rounded-lg bg-blue-400">
          <p>Acquia Copilot is an interactive, conversational experience, allowing you to ask follow-up questions and clarify doubts in real time using generative AI.</p>
        </div>
        <p>Don&apos;t have an account?&nbsp; 
        <button onClick={() => openDraw('search')} className="text-blue-600 hover:underline">Use search</button>.
        </p>
      </div>
    </div>
}

export function PreviewButton() {
  const { limit, acknowledge } = useCopilotPreview()
  const [clicked, setClicked] = useState(false);
  const dispatch = useAppDispatch()
  function login() {
    dispatch(ack('aiGuidelines'))
    signIn('okta')
    setClicked(true)
  }
  function tryCopilot() {
    dispatch(ack('aiGuidelines'))
    acknowledge()
  }
  return <div className="flex items-center h-full">
      <div className="p-4 h-fit w-fit mx-auto text-navy-600 text-center pb-24">
        <div className="flex gap-2 items-center mx-auto w-fit">
          <HugSvg className="w-48 mx-auto mt-12" />
        </div>
        <h2 className="text-xl font-medium my-4 max-w-sm mx-auto">Acquia Copilot is coming soon for customers</h2>
        <div className="max-w-sm p-4 rounded-lg bg-blue-400 mx-auto">
          <p>Acquia Copilot is an interactive, conversational experience, allowing you to ask follow-up questions and clarify doubts in real time using generative AI.</p>
          <p>This is an early preview for all users. Try it now by sending up to {limit} messages to Copilot per day.</p>
        </div>
        <div className="flex gap-2 mt-4 items-center mx-auto w-fit">
          <Button onClick={() => tryCopilot()} className="flex items-center gap-2">Try Copilot Preview</Button>
          <Button onClick={() => login()} primary={false} color="yellow" className={classNames(clicked ? 'animate-pulse' : '', "flex items-center gap-2")}>{clicked ? 'Please wait' : 'Employee login'}</Button>
        </div>
        <p className="max-w-sm mx-auto text-sm">By using Acquia Copilot, you are agreeing to our <a className="text-blue-600 underline" target="_blank" href="https://www.acquia.com/legal/ai-user-guidelines">AI user guidelines</a> and understand that Copilot’s responses are for informational purposes only.</p>
      </div>
    </div>
}

export function ChatButton({ className }:{
  className?: string
}) {
  const { openDraw, draw, close } = useSidebarDraw()
  const isActive = draw == 'chat';

  function toggle() {
    if (draw == 'chat') {
      close();
    }
    else {
      openDraw('chat');
    }
  }

  return <button 
    aria-label="Chat" title="Chat" 
    className={classNames(
      className, 
      'border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', 
      isActive ? 'text-white bg-navy-600' : ''
    )} 
    onClick={toggle}
  >
    <SparklesIcon className="w-[24px]" />
  </button>
}

export function SearchButton({ className }: {
  className?: string
}) {
  const { products } = useProducts()
  const pathname = usePathname()
  const { isOpen, draw, openDraw, close, current, search } = useSearch()

  const isVisible = isOpen && draw == 'search';

  const toggleSearch = () => {
    if (!isVisible && !current) {
      const inContextProducts = products.filter(p => pathname.startsWith(p.path.alias))
      if (inContextProducts.length) {
        const productName = inContextProducts.reduce((a, b) => a.path.alias.length > b.path.alias.length ? a : b).name;
        return search('', { products: [ productName ] } );
      }
      return openDraw('search')
    }
    else {
      return isVisible ? close() : openDraw('search')
    }
  }

  return <button 
    aria-label="Search" title="Search" 
    className={classNames(className, 'border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', isVisible ? 'text-white bg-navy-600' : '')} 
    onClick={toggleSearch}
  >
    <MagnifyingGlassIcon className="w-[24px]" />
  </button>
}