'use client'

import { useUserInformation } from "@/contexts/UserProvider"
import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { sendGTMEvent } from "@next/third-parties/google"
import { useProducts } from "@/contexts/ProductsProvider"
import type { DrupalTaxonomyTermProduct } from "@/types"
import { SupportCTA } from "@/components/widget/SupportCTA"
import { Button } from "@/components/base/Button"
import { useSearch } from "@/contexts/SearchProvider"

function trackSupportCTA(query?: string | null) {
  if (typeof query == "string") {
    sendGTMEvent({
      event: 'supportFromSearch',
      value: query
    })
  }
}

type SupportURL = Pick<DrupalTaxonomyTermProduct, 'name' | 'id' | 'field_support_center_url'>

export const SupportPrompt = ({query}: {
  query?: string | null
}) => {

  const { isAcquian } = useUserInformation()
  const { clear, current, input } = useSearch()
  const { products } = useProducts()

  if (isAcquian && query) {
    return <div className="rounded-lg p-5 pb-2 bg-gray-400">
      <div className="flex gap-1 text-blue-600 text-sm mb-2"><EyeIcon className="w-4" /> Acquian only</div>
      <h3 className="text-blue-600 text-xl font-medium">{"Can't find what you're looking for?"}</h3>
      <p className="mb-2">If you think you&apos;ve found a gap in our content, let us know and make a request to our content team.</p>
      <p>This will take you to Jira to create the request.</p>
      <p className="my-2 py-4">
        <a className="transition-colors duration-300 p-4 hover:bg-blue-800 text-white bg-blue-600 rounded after:bg-white" 
          href="https://acquia.atlassian.net/secure/CreateIssue.jspa?issuetype=3&pid=10110" target="_blank">Make a content request</a>
      </p>
    </div>
  }

  const support_urls:SupportURL[] = products
    .filter(p => {
      if (!current?.filters?.products || current.filters.products.length == 0) {
        return true
      }
      return current.filters.products?.includes(p.name)
    })
    .reduce((p, c) => {
      const urls = p.map(u =>  u.field_support_center_url?.uri)
      if (c.field_support_center_url === null) {
        return p
      }
      if (!urls.includes(c.field_support_center_url?.uri)) {
        p.push(c)
      }
      return p
    }, [] as SupportURL[])

  return <div className="w-fit mx-auto">
    <p className="text-center">Cannot find what you need?</p>
    <div className="grid grid-cols-2 gap-4">
      <SupportCTA supportUrls={support_urls} onClick={() => trackSupportCTA(query)} />
      <Button
        onClick={() => {
          clear()
          input.current?.focus()
        }}
        color='blue'
        primary={false}>
          <span className="flex gap-2 items-center">
            <MagnifyingGlassIcon className="w-4 group-hover:text-white" />New search
          </span>
      </Button>
    </div>
  </div>
}
