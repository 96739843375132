'use client'

import { useUserInformation } from "@/contexts/UserProvider"
import { EyeIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { sendGTMEvent } from "@next/third-parties/google"
import { useProducts } from "@/contexts/ProductsProvider"
import type { DrupalTaxonomyTermProduct } from "@/types"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { useState } from "react"
import { Dialog } from "@headlessui/react"
import { Link } from "@/components/base/Button"
import { DEFAULT_SUPPORT_CENTER_URL, isDefaultSupportUrl } from "@/components/widget/SupportCTA"
import { useCopilotPreview } from "@/contexts/CopilotPreviewProvider"
import { Countdown } from "@/components/widget/Countdown.client"


type SupportURL = Pick<DrupalTaxonomyTermProduct, 'name' | 'id' | 'field_support_center_url'>

export const SupportHelp = ({isChat = false}: {isChat?: boolean}) => {

  const { isAcquian, isLoggedIn } = useUserInformation()
  const { enabled, limit, messageCount, oldestMessageDate } = useCopilotPreview()
  const { products: globalProducts } = useProducts()
  const { products } = useContentFromRoute()
  const [ useModal, setUseModal ] = useState(false)

  if ( isAcquian) {
    return (isChat ? <></> : <div className="rounded-lg p-5 pb-2 bg-gray-400">
      <div className="flex gap-1 text-blue-600 text-sm mb-2"><EyeIcon className="w-4" /> Acquian only</div>
      <h3 className="text-blue-600 text-xl font-medium">{"Can't find what you're looking for?"}</h3>
      <p className="mb-2">If you think you&apos;ve found a gap in our content, let us know and make a request to our content team.</p>
      <p>This will take you to Jira to create the request.</p>
      <p className="my-2 py-4">
        <a className="transition-colors duration-300 p-4 hover:bg-blue-800 text-white bg-blue-600 rounded after:bg-white" href="https://acquia.atlassian.net/secure/CreateIssue.jspa?issuetype=3&pid=10110" target="_blank">Make a content request</a>
      </p>
    </div>)
  }

  if (enabled && !isLoggedIn) {
    return <div className="text-gray-800 text-sm w-fit max-w-xs ml-auto text-right italic">
      <h6 className="font-medium mb-0">Copilot preview</h6>
      <p className="mt-0">You have {Math.max(limit - messageCount, 0)} messages remaining.</p>
      { messageCount >= limit && <>
        <p>
          You will be able to send additional messages to Copilot in <Countdown targetDate={oldestMessageDate.getTime() + 24 * 60 * 60 * 1000} />.
        </p>
      </>}
    </div>
  }

  const support_urls = (products ?? globalProducts).filter(p => !isDefaultSupportUrl(p.field_support_center_url?.uri)) as SupportURL[]

  support_urls.push({
    id: 'global',
    name: 'Acquia',
    field_support_center_url: {
      uri: DEFAULT_SUPPORT_CENTER_URL
    }
  })


  function supportClick() {
    sendGTMEvent({
      event: 'supportFromChat',
      value: true
    })
    if (support_urls.length == 1) {
      const uri = support_urls[0].field_support_center_url?.uri;
      window.open(uri, "_blank");
    }
    else {
      setUseModal(true);
    }
  }

  return <p className="text-gray-800 text-sm w-fit ml-auto">
    Not getting the answers you are looking for?&nbsp;
    <button onClick={supportClick} className="text-blue-600 hover:underline">Contact our support team.</button>
    { useModal && (
      <Dialog open={useModal} onClose={() => setUseModal(false)} className="relative z-50">
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/40">
          <Dialog.Panel className="w-full max-w-lg rounded-lg overflow-hidden bg-white shadow-lg">
            <Dialog.Title className="text-lg font-medium font-display bg-navy-600 text-white px-8 py-4 flex items-center">
              Support centers
              <span className="flex-grow" />
              <button onClick={() => setUseModal(false)} className="hover:bg-white/20 rounded p-1">
                <span className="sr-only">Close</span>
                <XMarkIcon className="w-4" role="none" />
              </button>
            </Dialog.Title>
            <Dialog.Description className="px-8">Select which support center to contact</Dialog.Description>
            <div className="flex flex-col gap-4 fit-w mx-auto px-8 pb-8">
              {support_urls.map(support => (
                <Link onClick={() => sendGTMEvent({
                  event: 'supportFromChat',
                  value: true
                })} primary={support.field_support_center_url?.uri != DEFAULT_SUPPORT_CENTER_URL} key={support.id} href={support.field_support_center_url?.uri} target="_blank">{support.name} Support</Link>
              ))}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    )}
  </p>
}
