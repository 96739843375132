import { Popover, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { InfoBubble } from './InfoBubble'

export const Info = ({children, className, pointer = 'above'}:{
  children?: React.ReactNode
  className?: string
  pointer?: 'above' | 'below' | 'left' | 'right'
}) => {
  return <Popover className='relative'>
    <Popover.Button><InformationCircleIcon className='w-5 overflow-visible' /></Popover.Button>
    <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
      <Popover.Panel className={classNames('absolute', className)}>
        <InfoBubble pointer={pointer}>
          {children}
        </InfoBubble>
      </Popover.Panel>
    </Transition>
  </Popover>
}
