'use client';

import { useChat } from "@/contexts/ChatProvider";
import { useCopilotPreview } from "@/contexts/CopilotPreviewProvider";
import { useUserInformation } from "@/contexts/UserProvider";
import { ThreadChatMessage } from "@/lib/copilot/types";
import { searchParamsSearch } from "@/lib/searchParams";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useQueryStates } from "nuqs";
import { useEffect, useRef, useState } from "react";

export const Input = () => {
  const { isLoading, input, handleInputChange, createMessage, setInput, messages, hasMessage, append, setThreadId, threadId } = useChat();
  const { enabled, limit, messageCount, submitMessage } = useCopilotPreview();
  const { sessionId } = useUserInformation();
  const [{ searchQuery }] = useQueryStates(searchParamsSearch)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const maxLength = 280
  const formRef = useRef<HTMLFormElement | null>(null);

  const defaultInputFromSearchQuery = !input && searchQuery && messages.length === 0;
  const [isManualChange, setIsManualChange] = useState(false);

  const getLastUserQuestion = (messages: ThreadChatMessage[]) => {
    // Iterate over the messages array in reverse
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];
      // Check if the role is "user"
      if (message.role === "user") {
        return message.content; // Return the content of the message
      }
    }

    return null; // Return null if no user message is found
  }

  const isSearchQueryDifferent = searchQuery && searchQuery !== input;
  const isFirstMessageQueryDifferent = getLastUserQuestion(messages) !== searchQuery;
  const shouldSetInput = !isManualChange && (defaultInputFromSearchQuery || (isSearchQueryDifferent && isFirstMessageQueryDifferent));

  useEffect(() => {
    if (shouldSetInput) {
      setInput(searchQuery);
    }
  }, [ searchQuery, setInput, shouldSetInput]);

  useEffect(() => {
    if (!threadId && sessionId) {
      setThreadId(sessionId)
    }
  })

  // Reset the manual change flag when searchQuery changes
  useEffect(() => {
    setIsManualChange(false);
  }, [searchQuery]);


  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '0'; // Reset height to auto
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
      textarea.rows = Math.round(textarea.scrollHeight / 32);
    }
  };

  useEffect(() => {
    adjustHeight();
    setIsManualChange(true);
  }, [input]);

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();  // Prevent newline from being added
      append(createMessage(input), !enabled || messageCount < limit)
      setInput('')
    }
  };

  const inputLength = textareaRef.current?.value.length ?? 0
  const maxLimitHit = inputLength >= maxLength
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const maxLimitHit = event.target.value.length >= maxLength
    if (maxLimitHit) {
      event.stopPropagation()
      setInput(event.target.value.substring(0, maxLength) ?? '')
      return
    }
    handleInputChange(event)
  }

  const disableSubmission = isLoading || (enabled && messageCount >= limit && hasMessage('aiCopilotPreview'))
  let placeholderText = "Message Copilot"
  if (isLoading) {
    placeholderText = "Awaiting response..."
  } else if (enabled && messageCount >= limit && hasMessage('aiCopilotPreview')) {
    placeholderText = "Copilot Preview is now disabled. Please try again later."
  }

  return <form ref={formRef} onSubmit={submitMessage} className="pt-4">
    <p title="Character limit" className={classNames(
      'text-xs text-right m-0',
      maxLimitHit ? 'text-orange-600' : 'text-gray-800'
    )}>{Math.min(inputLength, maxLength)}/{maxLength}<span className="sr-only"> characters</span></p>
    <span className="flex border border-gray-600 rounded bg-white">
  <textarea
    ref={textareaRef}
    id="chat-input"
    className="w-full p-2 max-h-min resize-none placeholder:text-gray-700 bg-transparent disabled:bg-gray-400 disabled:text-gray-600"
    disabled={disableSubmission}
    value={input}
    placeholder={placeholderText}
    onKeyDown={handleKeyDown}
    onChange={onInputChange}
    />
  <button
    className="disabled:cursor-not-allowed"
    disabled={ disableSubmission || maxLimitHit || input.length === 0}
    title="Send"
    type="submit"
  >
    <PaperAirplaneIcon className="w-4 mx-2" />
  </button>
    </span>
</form>
}