'use client'
import { useChat } from '@/contexts/ChatProvider'
import { Dialog } from '@headlessui/react';
import { useState } from 'react'
import { sendChatFeedback } from './AcquiaFeedback.action';
import { Button } from '@/components/base/Button';
import { EyeIcon } from '@heroicons/react/24/outline';
import { useUserInformation } from '@/contexts/UserProvider';

export const AcquiaFeedback = () => {
  const { messages } = useChat();
  const [isOpen, setIsOpen] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { isAcquian } = useUserInformation()

  if (!isAcquian) return null;

  const sendChatFeedbackWithMessages = sendChatFeedback.bind(null, messages);

  return <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="rounded-lg p-5 bg-gray-400 w-full max-w-lg mx-2 shadow-lg">
          {isSubmitted ? (
            <div className='text-center'>
              <Dialog.Title className="text-blue-600 text-xl font-medium">Thank you for your feedback!</Dialog.Title>
              <p>A DOC ticket in Jira will be created with your feedback. You should recieve a Jira notification to track actions to your feedback.</p>
              <button type='submit' onClick={() => setIsOpen(false)} className='transition-colors duration-300 p-4 hover:bg-blue-800 text-white bg-blue-600 rounded'>Close</button>
            </div>
          ) : (
            <>
              <div className="flex gap-1 text-blue-600 text-sm mb-2"><EyeIcon className="w-4" /> Acquian only</div>
              <Dialog.Title className="text-blue-600 text-xl font-medium">What was wrong?</Dialog.Title>
              <form action={sendChatFeedbackWithMessages} onSubmit={() => setIsSubmitted(true)}>
                <p>
                  Tell us what was wrong and how it to fix it:
                </p>
                <textarea name='feedback' required className='w-full p-2 rounded border border-gray-600' rows={5} />
                <div className='flex gap-4 my-2'>
                  <label>
                    <input type="checkbox" name="inaccurate" title="Inaccurate" /> Inaccurate
                  </label>
                  <label>
                    <input type="checkbox" name="bad_citations" title="Bad citations" /> Bad citations
                  </label>
                  <label>
                    <input type="checkbox" name="misleading" title="Misleading" /> Misleading
                  </label>
                </div>
                <p>Your feedback and chat log will be send for further investigation.</p>
                <div className='flex gap-4 mt-12 justify-end'>
                  <Button type="submit" color='blue'>Submit</Button>
                  <Button onClick={() => setIsOpen(false)} primary={false}>Cancel</Button>
                </div>
              </form>
            </>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
}