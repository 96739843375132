'use client'
import { Body } from "@/components/wysiwyg/Body";
import { searchResultHit } from "../../../lib/search/server-actions.server";
import Link from "next/link";
import { usePathname } from "next/navigation";
import classNames from "classnames";
import { sendGTMEvent } from '@next/third-parties/google'
import { useProducts } from "@/contexts/ProductsProvider";
import { DrupalTaxonomyTermProduct } from "@/types";
import { ProductLogo } from "@/components/assets/ProductLogo";

export const SearchResult = ({href, highlight, sourceName, metadata}: Pick<searchResultHit, 'href' | 'highlight' | 'sourceName' | 'metadata' >) => {
  const pathname = usePathname()
  const { products } = useProducts()

  const meta = new Map()
  metadata.forEach(item => meta.set(item.key, item.value))

  // Hack to make production links relative.
  let rel = href.startsWith('https://docs.acquia.com/') ? href.replace('https://docs.acquia.com', '') : href

  // Conductor source stores URL in metadata.
  if (sourceName == '1_21_conductor_knowledge_base') {
    rel = meta.get('View href')[0]
    href = rel;
  }
  if (sourceName == "1_22_vwo_knowledge_bases") {
    rel = meta.get('View href')[0]
    href = rel;
  }

  // Acquia DAM articles don't live on support-acquia.force.com.
  if (sourceName == "server_scfull_salesforce" && meta.has('Product Names') && meta.get('Product Names')[0] == 'Acquia DAM') {
    href = href.replace('support-acquia.force.com', 'community.acquia.com/acquiadam')
    rel = href
  }

  const isActive = rel == pathname
  const target = rel.startsWith('http') ? '_blank' : undefined

  const goto = () => {
    sendGTMEvent({
      event: 'searchResultSelect',
      value: rel
    })
  }

  const resultProducts = findProducts(metadata, products)
  
  return (
    <Link 
      onClick={goto} 
      href={rel}
      target={target}
      className={classNames(
        "after:hidden p-4 rounded border-gray-400 border-2 hover:bg-gray-400 focus:bg-gray-400 focus:outline-2 focus:outline-blue-600", 
        isActive ? 'bg-gray-400' : ''
      )}>
      { resultProducts.length > 0 && (
        <span className="flex gap-1 items-end font-medium">
          {resultProducts.map(product => (
            <ProductLogo title={product.name} key={product.id} name={product.field_product_logo} className="w-4 pb-1" />
          ))} <span>{resultProducts.pop()?.name}</span>
        </span>
      )}
      <h2 className="font-display text-lg my-1 text-navy-600">
        <span className={target == '_blank' ? 'external reference' : ''}>
          {highlight.TitleToDisplayString}
        </span>
      </h2>
      <div>
        <Body value={highlight.SummaryToDisplay[0]} />
      </div>
      <p className="italic text-blue-800 underline text-sm my-1 truncate">{href}</p>
    </Link>
  )
}

function findProducts(metadata: searchResultHit["metadata"], products: DrupalTaxonomyTermProduct[]) {
  const product_metadata = metadata.filter(m => ['Products', 'Product Names'].includes(m.key))
  if (product_metadata.length == 0) {
    return []
  }
  return products.filter(p => product_metadata.filter(m => m.value.includes(p.name)).length > 0)
}